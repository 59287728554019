* {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    --primary-color: #32a7e2;
    --secondary-color: #f3e3e2;
  }

  *:focus {
    outline: none !important;
  }

  :root {
    --app-height: 100%;
    --app-width: 100%;
 }
 
 #root {
  position: relative;
  display: flex;
  flex: 1;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  flex-direction: column;
 }
 
 html {
   height: 100vh;
   min-height: -webkit-fill-available;
   scrollbar-width: none;
   overflow: hidden;
 }
 
 body {
  height: 100%;
  position: fixed;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
   display: flex;
   align-items: center;
   align-content: center;
   justify-content: center;
   overflow: hidden;
   top:0px;
   left:0px;
   overflow: hidden;
   font-family: "brandon-grotesque", "Brandon Grotesque", "Source Sans Pro", "Segoe UI", Frutiger, "Frutiger Linotype", "Dejavu Sans", "Helvetica Neue", Arial, sans-serif;
   text-rendering: optimizeLegibility;
   -webkit-font-smoothing: antialiased;
   width: 100vw;
   background-color: #373944!important;
   -ms-overflow-style: none;
   margin: unset;
  }

  body > iframe {
    display: none;
  }

  body::-webkit-scrollbar, ::-webkit-scrollbar-thumb, ::-webkit-scrollbar {
    display: none;
  }

  input:-webkit-autofill,input:-webkit-autofill:hover,input:-webkit-autofill:focus {
    padding-left: 2%!important;
    border: 1px solid rgba(247, 123, 123, 0);
    -webkit-text-fill-color: var(--primary-color);
    box-shadow: 0 0 0 1000px rgba(15, 63, 81, 0) inset;
    -webkit-box-shadow: 0 0 0 1000px rgba(15, 63, 81, 0) inset;
    transition: background-color 5000s ease-in-out 0s;
    }
  
  input, textarea, button {
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    border: 0;
    font-family: "brandon-grotesque", "Brandon Grotesque", "Source Sans Pro", "Segoe UI", Frutiger, "Frutiger Linotype", "Dejavu Sans", "Helvetica Neue", Arial, sans-serif;
    resize: none;
  }
  
  a, button, input[type=submit] {
    cursor: pointer;
  }
  
  ::-moz-selection {
    background: rgba(205, 144, 139, 0.2);
  }
  
  ::selection {
    background: rgba(205, 144, 139, 0.2);
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover, 
  input:-webkit-autofill:focus, 
  input:-webkit-autofill:active, 
  input:autofill {
    background-color: transparent !important;
    -webkit-box-shadow: 0 0 0 40px white inset !important;
}

#loading {
  width: 100%;
  height: 100%;
  z-index: 1000;
  display: flex;
}
#loading > div {
  display: flex;
  justify-content: center;
  margin: auto;
  width: 8vw;
  height: 8vh;
}
#loading > div > div {
  content: '';
  margin: 0% 5%;
  width: 15%;
  height: 23%;
  background: #8dd7d8;
  border-radius: 8px;
  animation: spin-a 1s infinite cubic-bezier(0.5, 0, 0.5, 1);
}

#loading > div > div:nth-child(2) {	
animation-delay:0.3s;
 }

#loading > div > div:nth-child(3) {	
animation-delay:0.6s;
 }

@keyframes spin-a {
0%  { transform: translateY(0px); } 
25%  { transform: translateY(-25px); height: 18%; }
30%  { height: 23%; }
75%  { transform: translateY(25px); height: 18%; }
80%  { height: 23%; }
100% { transform: translateY(0px); }
}

.dx-toolbar{
  padding-left: 20px!important;
}


.geosuggest__suggests-wrapper {
  position: relative;
  }
.rdrMonth {
  width: auto!important;
}

.rdrCalendarWrapper{
  width: 100%;
}