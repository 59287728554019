.mapAnime {
  background-color: rgba(20, 52, 89,.35);
  position: relative;
  display: flex;
  height: 100%;
  width: 100%;
}

.mapAnime path {
  fill: #546a85;
  -webkit-box-shadow: 0px 0px 17px 23px rgba(0, 0, 0, 0.94);
  -moz-box-shadow: 0px 0px 17px 23px rgba(0, 0, 0, 0.94);
  box-shadow: 0px 0px 17px 23px rgba(0, 0, 0, 0.94);
}

#Bg #Filler path {
  fill: #546a85;
  opacity: 0.8;
}

#Bg #Pulse #L1_pulse path:nth-child(1) {
  opacity: 0;
  animation: pulse infinite;
  animation-duration: 4s;
  animation-delay: 2.5s;
}
#Bg #Pulse #L1_pulse path:nth-child(2) {
  opacity: 0;
  animation: pulse infinite;
  animation-duration: 4s;
  animation-delay: 3s;
}
#Bg #Pulse #L1_pulse path:nth-child(3) {
  opacity: 0;
  animation: pulse infinite;
  animation-duration: 4s;
  animation-delay: 3.5s;
}
#Bg #Pulse #L1_pulse path:nth-child(4) {
  opacity: 0;
  animation: pulse infinite;
  animation-duration: 4s;
  animation-delay: 4s;
}
#Bg #Pulse #L1_pulse path:nth-child(5) {
  opacity: 0;
  animation: pulse infinite;
  animation-duration: 4s;
  animation-delay: 4.5s;
}
#Bg #Pulse #L1_pulse path:nth-child(6) {
  opacity: 0;
  animation: pulse infinite;
  animation-duration: 4s;
  animation-delay: 5s;
}
#Bg #Pulse #L1_pulse path:nth-child(7) {
  opacity: 0;
  animation: pulse infinite;
  animation-duration: 4s;
  animation-delay: 5.5s;
}
#Bg #Pulse #L1_pulse path:nth-child(8) {
  opacity: 0;
  animation: pulse infinite;
  animation-duration: 4s;
  animation-delay: 6s;
}
#Bg #Pulse #L5_pulse path {
  opacity: 0;
  animation: pulse_5 alternate infinite;
  animation-duration: 4s;
  animation-delay: 10s;
}
#Bg #Pulse #L8_pulse path {
  opacity: 0;
  animation: pulse_8 alternate infinite;
  animation-duration: 4s;
  animation-delay: 10s;
}

#IN g:nth-child(1):not(#Bg) path {
  animation: 4s level1 0.25s forwards;
  animation-timing-function: cubic-bezier(0.17, 0.67, 0.83, 0.67);
}
#IN g:nth-child(1):not(#Bg) path:nth-of-type(2) {
  animation: 0.25s50ms level1 0.25s forwards;
}
#IN g:nth-child(1):not(#Bg) path:nth-of-type(3) {
  animation: 0.25s100ms level1 0.25s forwards;
}
#IN g:nth-child(2):not(#Bg) path {
  animation: 4s level2 1.5s forwards;
  animation-timing-function: cubic-bezier(0.17, 0.67, 0.83, 0.67);
}
#IN g:nth-child(2):not(#Bg) path:nth-of-type(2) {
  animation: 1.5s50ms level2 1.5s forwards;
}
#IN g:nth-child(2):not(#Bg) path:nth-of-type(3) {
  animation: 1.5s100ms level2 1.5s forwards;
}
#IN g:nth-child(3):not(#Bg) path {
  animation: 4s level3 2.5s forwards;
  animation-timing-function: cubic-bezier(0.17, 0.67, 0.83, 0.67);
}
#IN g:nth-child(3):not(#Bg) path:nth-of-type(2) {
  animation: 2.5s50ms level3 2.5s forwards;
}
#IN g:nth-child(3):not(#Bg) path:nth-of-type(3) {
  animation: 2.5s100ms level3 2.5s forwards;
}
#IN g:nth-child(4):not(#Bg) path {
  animation: 4s level4 3.5s forwards;
  animation-timing-function: cubic-bezier(0.17, 0.67, 0.83, 0.67);
}
#IN g:nth-child(4):not(#Bg) path:nth-of-type(2) {
  animation: 3.5s50ms level4 3.5s forwards;
}
#IN g:nth-child(4):not(#Bg) path:nth-of-type(3) {
  animation: 3.5s100ms level4 3.5s forwards;
}
#IN g:nth-child(5):not(#Bg) path {
  animation: 4s level5 4.5s forwards;
  animation-timing-function: cubic-bezier(0.17, 0.67, 0.83, 0.67);
}
#IN g:nth-child(5):not(#Bg) path:nth-of-type(2) {
  animation: 4.5s50ms level5 4.5s forwards;
}
#IN g:nth-child(5):not(#Bg) path:nth-of-type(3) {
  animation: 4.5s100ms level5 4.5s forwards;
}
#IN g:nth-child(6):not(#Bg) path {
  animation: 4s level6 5.5s forwards;
  animation-timing-function: cubic-bezier(0.17, 0.67, 0.83, 0.67);
}
#IN g:nth-child(6):not(#Bg) path:nth-of-type(2) {
  animation: 5.5s50ms level6 5.5s forwards;
}
#IN g:nth-child(6):not(#Bg) path:nth-of-type(3) {
  animation: 5.5s100ms level6 5.5s forwards;
}
#IN g:nth-child(7):not(#Bg) path {
  animation: 4s level7 6.5s forwards;
  animation-timing-function: cubic-bezier(0.17, 0.67, 0.83, 0.67);
}
#IN g:nth-child(7):not(#Bg) path:nth-of-type(2) {
  animation: 6.5s50ms level7 6.5s forwards;
}
#IN g:nth-child(7):not(#Bg) path:nth-of-type(3) {
  animation: 6.5s100ms level7 6.5s forwards;
}
#IN g:nth-child(8):not(#Bg) path {
  animation: 4s level8 7.5s forwards;
  animation-timing-function: cubic-bezier(0.17, 0.67, 0.83, 0.67);
}
#IN g:nth-child(8):not(#Bg) path:nth-of-type(2) {
  animation: 7.5s50ms level8 7.5s forwards;
}
#IN g:nth-child(8):not(#Bg) path:nth-of-type(3) {
  animation: 7.5s100ms level8 7.5s forwards;
}
#IN g:nth-child(9):not(#Bg) path {
  animation: 4s level9 8s forwards;
  animation-timing-function: cubic-bezier(0.17, 0.67, 0.83, 0.67);
}
#IN g:nth-child(9):not(#Bg) path:nth-of-type(2) {
  animation: 8s50ms level9 8s forwards;
}
#IN g:nth-child(9):not(#Bg) path:nth-of-type(3) {
  animation: 8s100ms level9 8s forwards;
}
#IN g:nth-child(10):not(#Bg) path {
  animation: 4s level10 8.5s forwards;
  animation-timing-function: cubic-bezier(0.17, 0.67, 0.83, 0.67);
}
#IN g:nth-child(10):not(#Bg) path:nth-of-type(2) {
  animation: 8.5s50ms level10 8.5s forwards;
}
#IN g:nth-child(10):not(#Bg) path:nth-of-type(3) {
  animation: 8.5s100ms level10 8.5s forwards;
}

#NA g:nth-child(1):not(#Bg) path {
  animation: 4s level1 0.5s forwards;
  animation-timing-function: cubic-bezier(0.17, 0.67, 0.83, 0.67);
}
#NA g:nth-child(1):not(#Bg) path:nth-of-type(2) {
  animation: 0.5s50ms level1 0.5s forwards;
}
#NA g:nth-child(1):not(#Bg) path:nth-of-type(3) {
  animation: 0.5s100ms level1 0.5s forwards;
}
#NA g:nth-child(2):not(#Bg) path {
  animation: 4s level2 2s forwards;
  animation-timing-function: cubic-bezier(0.17, 0.67, 0.83, 0.67);
}
#NA g:nth-child(2):not(#Bg) path:nth-of-type(2) {
  animation: 2s50ms level2 2s forwards;
}
#NA g:nth-child(2):not(#Bg) path:nth-of-type(3) {
  animation: 2s100ms level2 2s forwards;
}
#NA g:nth-child(3):not(#Bg) path {
  animation: 4s level3 3s forwards;
  animation-timing-function: cubic-bezier(0.17, 0.67, 0.83, 0.67);
}
#NA g:nth-child(3):not(#Bg) path:nth-of-type(2) {
  animation: 3s50ms level3 3s forwards;
}
#NA g:nth-child(3):not(#Bg) path:nth-of-type(3) {
  animation: 3s100ms level3 3s forwards;
}
#NA g:nth-child(4):not(#Bg) path {
  animation: 4s level4 4s forwards;
  animation-timing-function: cubic-bezier(0.17, 0.67, 0.83, 0.67);
}
#NA g:nth-child(4):not(#Bg) path:nth-of-type(2) {
  animation: 4s50ms level4 4s forwards;
}
#NA g:nth-child(4):not(#Bg) path:nth-of-type(3) {
  animation: 4s100ms level4 4s forwards;
}
#NA g:nth-child(5):not(#Bg) path {
  animation: 4s level5 5s forwards;
  animation-timing-function: cubic-bezier(0.17, 0.67, 0.83, 0.67);
}
#NA g:nth-child(5):not(#Bg) path:nth-of-type(2) {
  animation: 5s50ms level5 5s forwards;
}
#NA g:nth-child(5):not(#Bg) path:nth-of-type(3) {
  animation: 5s100ms level5 5s forwards;
}
#NA g:nth-child(6):not(#Bg) path {
  animation: 4s level6 6s forwards;
  animation-timing-function: cubic-bezier(0.17, 0.67, 0.83, 0.67);
}
#NA g:nth-child(6):not(#Bg) path:nth-of-type(2) {
  animation: 6s50ms level6 6s forwards;
}
#NA g:nth-child(6):not(#Bg) path:nth-of-type(3) {
  animation: 6s100ms level6 6s forwards;
}
#NA g:nth-child(7):not(#Bg) path {
  animation: 4s level7 7s forwards;
  animation-timing-function: cubic-bezier(0.17, 0.67, 0.83, 0.67);
}
#NA g:nth-child(7):not(#Bg) path:nth-of-type(2) {
  animation: 7s50ms level7 7s forwards;
}
#NA g:nth-child(7):not(#Bg) path:nth-of-type(3) {
  animation: 7s100ms level7 7s forwards;
}
#NA g:nth-child(8):not(#Bg) path {
  animation: 4s level8 8s forwards;
  animation-timing-function: cubic-bezier(0.17, 0.67, 0.83, 0.67);
}
#NA g:nth-child(8):not(#Bg) path:nth-of-type(2) {
  animation: 8s50ms level8 8s forwards;
}
#NA g:nth-child(8):not(#Bg) path:nth-of-type(3) {
  animation: 8s100ms level8 8s forwards;
}
#NA g:nth-child(9):not(#Bg) path {
  animation: 4s level9 8.5s forwards;
  animation-timing-function: cubic-bezier(0.17, 0.67, 0.83, 0.67);
}
#NA g:nth-child(9):not(#Bg) path:nth-of-type(2) {
  animation: 8.5s50ms level9 8.5s forwards;
}
#NA g:nth-child(9):not(#Bg) path:nth-of-type(3) {
  animation: 8.5s100ms level9 8.5s forwards;
}
#NA g:nth-child(10):not(#Bg) path {
  animation: 4s level10 9s forwards;
  animation-timing-function: cubic-bezier(0.17, 0.67, 0.83, 0.67);
}
#NA g:nth-child(10):not(#Bg) path:nth-of-type(2) {
  animation: 9s50ms level10 9s forwards;
}
#NA g:nth-child(10):not(#Bg) path:nth-of-type(3) {
  animation: 9s100ms level10 9s forwards;
}

#EU g:nth-child(1):not(#Bg) path {
  animation: 4s level1 1s forwards;
  animation-timing-function: cubic-bezier(0.17, 0.67, 0.83, 0.67);
}
#EU g:nth-child(1):not(#Bg) path:nth-of-type(2) {
  animation: 1s50ms level1 1s forwards;
}
#EU g:nth-child(1):not(#Bg) path:nth-of-type(3) {
  animation: 1s100ms level1 1s forwards;
}
#EU g:nth-child(2):not(#Bg) path {
  animation: 4s level2 3s forwards;
  animation-timing-function: cubic-bezier(0.17, 0.67, 0.83, 0.67);
}
#EU g:nth-child(2):not(#Bg) path:nth-of-type(2) {
  animation: 3s50ms level2 3s forwards;
}
#EU g:nth-child(2):not(#Bg) path:nth-of-type(3) {
  animation: 3s100ms level2 3s forwards;
}
#EU g:nth-child(3):not(#Bg) path {
  animation: 4s level3 4s forwards;
  animation-timing-function: cubic-bezier(0.17, 0.67, 0.83, 0.67);
}
#EU g:nth-child(3):not(#Bg) path:nth-of-type(2) {
  animation: 4s50ms level3 4s forwards;
}
#EU g:nth-child(3):not(#Bg) path:nth-of-type(3) {
  animation: 4s100ms level3 4s forwards;
}
#EU g:nth-child(4):not(#Bg) path {
  animation: 4s level4 5s forwards;
  animation-timing-function: cubic-bezier(0.17, 0.67, 0.83, 0.67);
}
#EU g:nth-child(4):not(#Bg) path:nth-of-type(2) {
  animation: 5s50ms level4 5s forwards;
}
#EU g:nth-child(4):not(#Bg) path:nth-of-type(3) {
  animation: 5s100ms level4 5s forwards;
}
#EU g:nth-child(5):not(#Bg) path {
  animation: 4s level5 6s forwards;
  animation-timing-function: cubic-bezier(0.17, 0.67, 0.83, 0.67);
}
#EU g:nth-child(5):not(#Bg) path:nth-of-type(2) {
  animation: 6s50ms level5 6s forwards;
}
#EU g:nth-child(5):not(#Bg) path:nth-of-type(3) {
  animation: 6s100ms level5 6s forwards;
}
#EU g:nth-child(6):not(#Bg) path {
  animation: 4s level6 7s forwards;
  animation-timing-function: cubic-bezier(0.17, 0.67, 0.83, 0.67);
}
#EU g:nth-child(6):not(#Bg) path:nth-of-type(2) {
  animation: 7s50ms level6 7s forwards;
}
#EU g:nth-child(6):not(#Bg) path:nth-of-type(3) {
  animation: 7s100ms level6 7s forwards;
}
#EU g:nth-child(7):not(#Bg) path {
  animation: 4s level7 8s forwards;
  animation-timing-function: cubic-bezier(0.17, 0.67, 0.83, 0.67);
}
#EU g:nth-child(7):not(#Bg) path:nth-of-type(2) {
  animation: 8s50ms level7 8s forwards;
}
#EU g:nth-child(7):not(#Bg) path:nth-of-type(3) {
  animation: 8s100ms level7 8s forwards;
}
#EU g:nth-child(8):not(#Bg) path {
  animation: 4s level8 9s forwards;
  animation-timing-function: cubic-bezier(0.17, 0.67, 0.83, 0.67);
}
#EU g:nth-child(8):not(#Bg) path:nth-of-type(2) {
  animation: 9s50ms level8 9s forwards;
}
#EU g:nth-child(8):not(#Bg) path:nth-of-type(3) {
  animation: 9s100ms level8 9s forwards;
}
#EU g:nth-child(9):not(#Bg) path {
  animation: 4s level9 9.5s forwards;
  animation-timing-function: cubic-bezier(0.17, 0.67, 0.83, 0.67);
}
#EU g:nth-child(9):not(#Bg) path:nth-of-type(2) {
  animation: 9.5s50ms level9 9.5s forwards;
}
#EU g:nth-child(9):not(#Bg) path:nth-of-type(3) {
  animation: 9.5s100ms level9 9.5s forwards;
}
#EU g:nth-child(10):not(#Bg) path {
  animation: 4s level10 10s forwards;
  animation-timing-function: cubic-bezier(0.17, 0.67, 0.83, 0.67);
}
#EU g:nth-child(10):not(#Bg) path:nth-of-type(2) {
  animation: 10s50ms level10 10s forwards;
}
#EU g:nth-child(10):not(#Bg) path:nth-of-type(3) {
  animation: 10s100ms level10 10s forwards;
}

#AF g:nth-child(1):not(#Bg) path {
  animation: 4s level1 0.75s forwards;
  animation-timing-function: cubic-bezier(0.17, 0.67, 0.83, 0.67);
}
#AF g:nth-child(1):not(#Bg) path:nth-of-type(2) {
  animation: 0.75s50ms level1 0.75s forwards;
}
#AF g:nth-child(1):not(#Bg) path:nth-of-type(3) {
  animation: 0.75s100ms level1 0.75s forwards;
}
#AF g:nth-child(2):not(#Bg) path {
  animation: 4s level2 2.5s forwards;
  animation-timing-function: cubic-bezier(0.17, 0.67, 0.83, 0.67);
}
#AF g:nth-child(2):not(#Bg) path:nth-of-type(2) {
  animation: 2.5s50ms level2 2.5s forwards;
}
#AF g:nth-child(2):not(#Bg) path:nth-of-type(3) {
  animation: 2.5s100ms level2 2.5s forwards;
}
#AF g:nth-child(3):not(#Bg) path {
  animation: 4s level3 3.5s forwards;
  animation-timing-function: cubic-bezier(0.17, 0.67, 0.83, 0.67);
}
#AF g:nth-child(3):not(#Bg) path:nth-of-type(2) {
  animation: 3.5s50ms level3 3.5s forwards;
}
#AF g:nth-child(3):not(#Bg) path:nth-of-type(3) {
  animation: 3.5s100ms level3 3.5s forwards;
}
#AF g:nth-child(4):not(#Bg) path {
  animation: 4s level4 4.5s forwards;
  animation-timing-function: cubic-bezier(0.17, 0.67, 0.83, 0.67);
}
#AF g:nth-child(4):not(#Bg) path:nth-of-type(2) {
  animation: 4.5s50ms level4 4.5s forwards;
}
#AF g:nth-child(4):not(#Bg) path:nth-of-type(3) {
  animation: 4.5s100ms level4 4.5s forwards;
}
#AF g:nth-child(5):not(#Bg) path {
  animation: 4s level5 5.5s forwards;
  animation-timing-function: cubic-bezier(0.17, 0.67, 0.83, 0.67);
}
#AF g:nth-child(5):not(#Bg) path:nth-of-type(2) {
  animation: 5.5s50ms level5 5.5s forwards;
}
#AF g:nth-child(5):not(#Bg) path:nth-of-type(3) {
  animation: 5.5s100ms level5 5.5s forwards;
}
#AF g:nth-child(6):not(#Bg) path {
  animation: 4s level6 6.5s forwards;
  animation-timing-function: cubic-bezier(0.17, 0.67, 0.83, 0.67);
}
#AF g:nth-child(6):not(#Bg) path:nth-of-type(2) {
  animation: 6.5s50ms level6 6.5s forwards;
}
#AF g:nth-child(6):not(#Bg) path:nth-of-type(3) {
  animation: 6.5s100ms level6 6.5s forwards;
}
#AF g:nth-child(7):not(#Bg) path {
  animation: 4s level7 7.5s forwards;
  animation-timing-function: cubic-bezier(0.17, 0.67, 0.83, 0.67);
}
#AF g:nth-child(7):not(#Bg) path:nth-of-type(2) {
  animation: 7.5s50ms level7 7.5s forwards;
}
#AF g:nth-child(7):not(#Bg) path:nth-of-type(3) {
  animation: 7.5s100ms level7 7.5s forwards;
}
#AF g:nth-child(8):not(#Bg) path {
  animation: 4s level8 8.5s forwards;
  animation-timing-function: cubic-bezier(0.17, 0.67, 0.83, 0.67);
}
#AF g:nth-child(8):not(#Bg) path:nth-of-type(2) {
  animation: 8.5s50ms level8 8.5s forwards;
}
#AF g:nth-child(8):not(#Bg) path:nth-of-type(3) {
  animation: 8.5s100ms level8 8.5s forwards;
}
#AF g:nth-child(9):not(#Bg) path {
  animation: 4s level9 9s forwards;
  animation-timing-function: cubic-bezier(0.17, 0.67, 0.83, 0.67);
}
#AF g:nth-child(9):not(#Bg) path:nth-of-type(2) {
  animation: 9s50ms level9 9s forwards;
}
#AF g:nth-child(9):not(#Bg) path:nth-of-type(3) {
  animation: 9s100ms level9 9s forwards;
}
#AF g:nth-child(10):not(#Bg) path {
  animation: 4s level10 9.5s forwards;
  animation-timing-function: cubic-bezier(0.17, 0.67, 0.83, 0.67);
}
#AF g:nth-child(10):not(#Bg) path:nth-of-type(2) {
  animation: 9.5s50ms level10 9.5s forwards;
}
#AF g:nth-child(10):not(#Bg) path:nth-of-type(3) {
  animation: 9.5s100ms level10 9.5s forwards;
}

#SA g:nth-child(1):not(#Bg) path {
  animation: 4s level1 1.25s forwards;
  animation-timing-function: cubic-bezier(0.17, 0.67, 0.83, 0.67);
}
#SA g:nth-child(1):not(#Bg) path:nth-of-type(2) {
  animation: 1.25s50ms level1 1.25s forwards;
}
#SA g:nth-child(1):not(#Bg) path:nth-of-type(3) {
  animation: 1.25s100ms level1 1.25s forwards;
}
#SA g:nth-child(2):not(#Bg) path {
  animation: 4s level2 3.5s forwards;
  animation-timing-function: cubic-bezier(0.17, 0.67, 0.83, 0.67);
}
#SA g:nth-child(2):not(#Bg) path:nth-of-type(2) {
  animation: 3.5s50ms level2 3.5s forwards;
}
#SA g:nth-child(2):not(#Bg) path:nth-of-type(3) {
  animation: 3.5s100ms level2 3.5s forwards;
}
#SA g:nth-child(3):not(#Bg) path {
  animation: 4s level3 4.5s forwards;
  animation-timing-function: cubic-bezier(0.17, 0.67, 0.83, 0.67);
}
#SA g:nth-child(3):not(#Bg) path:nth-of-type(2) {
  animation: 4.5s50ms level3 4.5s forwards;
}
#SA g:nth-child(3):not(#Bg) path:nth-of-type(3) {
  animation: 4.5s100ms level3 4.5s forwards;
}
#SA g:nth-child(4):not(#Bg) path {
  animation: 4s level4 5.5s forwards;
  animation-timing-function: cubic-bezier(0.17, 0.67, 0.83, 0.67);
}
#SA g:nth-child(4):not(#Bg) path:nth-of-type(2) {
  animation: 5.5s50ms level4 5.5s forwards;
}
#SA g:nth-child(4):not(#Bg) path:nth-of-type(3) {
  animation: 5.5s100ms level4 5.5s forwards;
}
#SA g:nth-child(5):not(#Bg) path {
  animation: 4s level5 6.5s forwards;
  animation-timing-function: cubic-bezier(0.17, 0.67, 0.83, 0.67);
}
#SA g:nth-child(5):not(#Bg) path:nth-of-type(2) {
  animation: 6.5s50ms level5 6.5s forwards;
}
#SA g:nth-child(5):not(#Bg) path:nth-of-type(3) {
  animation: 6.5s100ms level5 6.5s forwards;
}
#SA g:nth-child(6):not(#Bg) path {
  animation: 4s level6 7.5s forwards;
  animation-timing-function: cubic-bezier(0.17, 0.67, 0.83, 0.67);
}
#SA g:nth-child(6):not(#Bg) path:nth-of-type(2) {
  animation: 7.5s50ms level6 7.5s forwards;
}
#SA g:nth-child(6):not(#Bg) path:nth-of-type(3) {
  animation: 7.5s100ms level6 7.5s forwards;
}
#SA g:nth-child(7):not(#Bg) path {
  animation: 4s level7 8.5s forwards;
  animation-timing-function: cubic-bezier(0.17, 0.67, 0.83, 0.67);
}
#SA g:nth-child(7):not(#Bg) path:nth-of-type(2) {
  animation: 8.5s50ms level7 8.5s forwards;
}
#SA g:nth-child(7):not(#Bg) path:nth-of-type(3) {
  animation: 8.5s100ms level7 8.5s forwards;
}
#SA g:nth-child(8):not(#Bg) path {
  animation: 4s level8 9.5s forwards;
  animation-timing-function: cubic-bezier(0.17, 0.67, 0.83, 0.67);
}
#SA g:nth-child(8):not(#Bg) path:nth-of-type(2) {
  animation: 9.5s50ms level8 9.5s forwards;
}
#SA g:nth-child(8):not(#Bg) path:nth-of-type(3) {
  animation: 9.5s100ms level8 9.5s forwards;
}
#SA g:nth-child(9):not(#Bg) path {
  animation: 4s level9 10s forwards;
  animation-timing-function: cubic-bezier(0.17, 0.67, 0.83, 0.67);
}
#SA g:nth-child(9):not(#Bg) path:nth-of-type(2) {
  animation: 10s50ms level9 10s forwards;
}
#SA g:nth-child(9):not(#Bg) path:nth-of-type(3) {
  animation: 10s100ms level9 10s forwards;
}
#SA g:nth-child(10):not(#Bg) path {
  animation: 4s level10 10.5s forwards;
  animation-timing-function: cubic-bezier(0.17, 0.67, 0.83, 0.67);
}
#SA g:nth-child(10):not(#Bg) path:nth-of-type(2) {
  animation: 10.5s50ms level10 10.5s forwards;
}
#SA g:nth-child(10):not(#Bg) path:nth-of-type(3) {
  animation: 10.5s100ms level10 10.5s forwards;
}

@keyframes level0 {
  0% {
    fill: red;
  }
  100% {
    fill: red;
  }
}
@keyframes level1 {
  0% {
    fill: #546a85;
  }
  100% {
    fill: #ff4c30;
  }
}
@keyframes level2 {
  0% {
    fill: #546a85;
    stroke-width: 0;
    stroke: #546a85;
    stroke-opacity: 0;
  }
  20% {
    stroke-width: 2;
    stroke-opacity: 1;
  }
  30% {
    stroke-width: 1;
    stroke-opacity: 0;
  }
  100% {
    fill: #BA5852;
    stroke-width: 1;
    stroke: #BA5852;
    stroke-opacity: 0;
  }
}
@keyframes level3 {
  0% {
    fill: #546a85;
    stroke-width: 0;
    stroke: #546a85;
    stroke-opacity: 0;
  }
  20% {
    stroke-width: 2;
    stroke-opacity: 1;
  }
  30% {
    stroke-width: 1;
    stroke-opacity: 0;
  }
  100% {
    fill: #BA5852;
    stroke-width: 1;
    stroke: #BA5852;
    stroke-opacity: 0;
  }
}
@keyframes level4 {
  0% {
    fill: #546a85;
    stroke-width: 0;
    stroke: #546a85;
    stroke-opacity: 0;
  }
  20% {
    stroke-width: 2;
    stroke-opacity: 1;
  }
  30% {
    stroke-width: 1;
    stroke-opacity: 0;
  }
  100% {
    fill: #985D62;
    stroke-width: 1;
    stroke: #985D62;
    stroke-opacity: 0;
  }
}
@keyframes level5 {
  0% {
    fill: #546a85;
    stroke-width: 0;
    stroke: #546a85;
    stroke-opacity: 0;
  }
  20% {
    stroke-width: 2;
    stroke-opacity: 1;
  }
  30% {
    stroke-width: 1;
    stroke-opacity: 0;
  }
  100% {
    fill: #b06e6f;
    stroke-width: 1;
    stroke: #b06e6f;
    stroke-opacity: 0;
  }
}
@keyframes level6 {
  0% {
    fill: #546a85;
    stroke-width: 0;
    stroke: #546a85;
    stroke-opacity: 0;
  }
  20% {
    stroke-width: 2;
    stroke-opacity: 1;
  }
  30% {
    stroke-width: 1;
    stroke-opacity: 0;
  }
  100% {
    fill: #985D62;
    stroke-width: 1;
    stroke: #985D62;
    stroke-opacity: 0;
  }
}
@keyframes level7 {
  0% {
    fill: #546a85;
    stroke-width: 0;
    stroke: #546a85;
    stroke-opacity: 0;
  }
  20% {
    stroke-width: 2;
    stroke-opacity: 1;
  }
  30% {
    stroke-width: 1;
    stroke-opacity: 0;
  }
  100% {
    fill: #7b6c7c;
    stroke-width: 1;
    stroke: #7b6c7c;
    stroke-opacity: 0;
  }
}
@keyframes level8 {
  0% {
    fill: #546a85;
    stroke-width: 0;
    stroke: #546a85;
    stroke-opacity: 0;
  }
  20% {
    stroke-width: 2;
    stroke-opacity: 1;
  }
  30% {
    stroke-width: 1;
    stroke-opacity: 0;
  }
  100% {
    fill: #766374;
    stroke-width: 1;
    stroke: #766374;
    stroke-opacity: 0;
  }
}
@keyframes pulse {
  0% {
    fill: #ff4c30;
    stroke-width: 0;
    stroke: #ff4c30;
    opacity: 0.1;
  }
  20% {
    fill: #ff4c30;
    stroke: #ff4c30;
    stroke-width: 10;
    opacity: 0.6;
  }
  40% {
    fill: #ff4c30;
    stroke: #ff4c30;
    stroke-width: 15;
    opacity: 0.1;
  }
}
@keyframes pulse_5 {
  0% {
    fill: #b06e6f;
    opacity: 0.1;
  }
  100% {
    fill: #7b6c7c;
    opacity: 1;
  }
}
@keyframes pulse_8 {
  0% {
    fill: #766374;
    opacity: 0.1;
  }
  100% {
    fill: #985D62;
    opacity: 1;
  }
}