
.btn-scan  svg {
  position: relative;
  z-index: 2;
  pointer-events: none;
  width: 180px;
  height: 90px;
  cursor: pointer;
  transform: translate3d(0, -8%, 0);
}

/*p { margin-bottom:unset;}*/

.btn-scan {
  position: relative;
  width: 100%;
  min-width: 348px;
  background-color: #292a38;
  margin: 0;
  padding: 0;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70px;
}

.btn-scan .submit,
.btn-scan .uploading,
.btn-scan .feedback {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  transition: color 0.5s;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 18px;
  font-weight: 100;
  text-decoration: none;
  text-align: center;
  color: #fff;
  line-height: 32px;
  letter-spacing: 0.1rem;
  border-radius: 16px;
  z-index: 3;
}

.btn-scan .submit {
  color: #1ecd97;
  font-weight: normal;
  opacity: 0.6;
}

.btn-scan .uploading {
  color: rgba(255, 255, 255, 0);
}

.btn-scan .submit:hover,
.btn-scan .uploading:hover {
  background: rgba(255, 255, 255, 0.1);
}

.btn-scan .feedback {
  z-index: 1;
}
.btn-scan .feedback::after {
  visibility: hidden;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 120px;
  height: 100%;
  background: rgba(30, 205, 151, 0.6);
  border-radius: 40px;
  transition: background 0.2s, transform 0.2s;
}

#middle {
  transition: fill 0.5s cubic-bezier(0.445, 0.05, 0.55, 0.95);
  fill: #3e3f4c;
}

#top,
#bottom {
  fill: none;
  stroke-width: 6px;
  stroke-linecap: round;
  stroke: #1ecd97;
  stroke-dashoffset: 0px;
  transition: stroke-dashoffset 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25), stroke-dasharray 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25), stroke 0.5s cubic-bezier(0.445, 0.05, 0.55, 0.95);
  stroke-dasharray: 295px 1000px;
}

.uploading-progress .submit {
  color: rgba(255, 255, 255, 0);
  transition: color, 2s;
}

.uploading-start a {
  color: rgba(255, 255, 255, 0);
}
.uploading-start .uploading {
  color: #fff;
  -webkit-animation: opacity 0.4s ease infinite;
          animation: opacity 0.4s ease infinite;
}
.uploading-start .feedback::after {
  visibility: visible;
  background-color: rgba(255, 255, 255, 0);
  transform: scale(1.5, 2);
}
.uploading-start #top,
.uploading-start #bottom {
  transition: stroke-dashoffset 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25), stroke-dasharray 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25);
  stroke-dasharray: 10px 1000px;
}

.uploading-progress #top,
.uploading-progress #bottom {
  transition: stroke-dashoffset 1s linear, stroke-dasharray 1s linear;
  stroke-dasharray: 10px 1000px;
  stroke-dashoffset: -150px;
}

.uploading-end .uploading {
  color: rgba(255, 255, 255, 0);
}
.uploading-end #top,
.uploading-end #bottom {
  stroke: #f5f6f7;
}
.uploading-end #top {
  transition: stroke-dashoffset 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25), stroke-dasharray 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25), stroke 0.5s cubic-bezier(0.445, 0.05, 0.55, 0.95);
  stroke-dasharray: 30px 1000px;
  stroke-dashoffset: -523px;
}
.uploading-end #bottom {
  transition: stroke-dashoffset 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25), stroke-dasharray 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25), stroke 0.5s cubic-bezier(0.445, 0.05, 0.55, 0.95);
  stroke-dasharray: 10px 1000px;
  stroke-dashoffset: -530px;
}
.uploading-end #middle {
  fill: #1ecd97 !important;
}

.uploading-error .uploading {
  color: rgba(255, 255, 255, 0);
}
.uploading-error #top,
.uploading-error #bottom {
  stroke: #ff2205;
}
.uploading-error #top {
  transition: stroke-dashoffset 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25), stroke-dasharray 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25), stroke 0.5s cubic-bezier(0.445, 0.05, 0.55, 0.95),transform 0.5s;
  stroke-dasharray: 30px 1000px;
  stroke-dashoffset: -523px;
  transform: translateX(-11px);
}
.uploading-error #bottom {
  transition: stroke-dashoffset 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25), stroke-dasharray 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25), stroke 0.5s cubic-bezier(0.445, 0.05, 0.55, 0.95),transform 0.5s;
  stroke-dasharray: 30px 1000px;
  stroke-dashoffset: -530px;
  transform: translateX(11px);
}
.uploading-error #middle {
  fill: #ff6961 !important;
  stroke: #ff2205;
  stroke-width: 5px;
}

.cardDeck {
  position: relative;
  margin-top: 3%;
}

.cardDeck:hover div {
  position: relative!important;
  margin: 1% 0!important;
}

/**
 *animate
 */
@-webkit-keyframes opacity {
  0% {
    opacity: 0.8;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 0.8;
  }
}
@keyframes opacity {
  0% {
    opacity: 0.8;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 0.8;
  }
}